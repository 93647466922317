<template>
  <div>
    <v-container>
      <v-toolbar-title
        >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.SEARCH_OPEN_EVENT") }}
      </v-toolbar-title>
      <v-row>
        <v-col cols="12" md="12"> </v-col>
      </v-row>
    </v-container>
    <div class="d-flex flex-column-fluid">
      <div class="container">
        <!--begin::Row-->
        <div class="row">
          <div class="col-xl-4" id="resume_user_reservation_overview">
            <div class="card card-custom card-stretch">
              <div class="card-body pt-4">
                <div class="d-flex justify-content-end"></div>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_USER") }}</a
                    >
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="py-2">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.NAME") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.name
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.SURNAME") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      convertDate(citizenResume.surname)
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.fiscal_code
                    }}</a>
                  </div>
                </div>
                <v-divider></v-divider>
                <!--begin::Contact-->
                <div class="py-2">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.DATE_OF_BIRTH")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      convertDate(citizenResume.date_of_birth)
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.CITY_OF_BIRTH")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.city_of_birth.denomination
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_ADDRESS")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.residence_address
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_CITY")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.city_of_residence.denomination
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.EMAIL") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.email
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.PHONE") }}:</span
                    >
                    <span class="text-muted">{{ citizenResume.phone }}</span>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.MOBILE_PHONE")
                      }}:</span
                    >
                    <span class="text-muted">{{
                      citizenResume.mobile_phone
                    }}</span>
                  </div>
                </div>
                <!--end::Contact-->
              </div>
            </div>
          </div>
          <div class="col-xl-4" id="resume_event_reservation_overview">
            <div class="card card-custom card-stretch">
              <div class="card-body pt-4">
                <div class="d-flex justify-content-end"></div>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_EVENT") }}</a
                    >
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="py-2">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.TITLE") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      eventResume.title
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      eventResume.code
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.LOCALITY") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      eventResume.city.denomination
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.ADDRESS") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      eventResume.address
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      citizenResume.fiscal_code
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.BEGIN_DATE") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      this.convertDateTime(eventResume.start_date)
                    }}</a>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.END_DATE") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                      this.convertDateTime(eventResume.end_date)
                    }}</a>
                  </div>
                </div>
                <!--end::Contact-->
              </div>
            </div>
          </div>

          <div class="col-xl-4" id="resume_reservation_overview">
            <div class="card card-custom card-stretch">
              <div class="card-body pt-4">
                <div class="d-flex justify-content-end"></div>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                      class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                      >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESERVATION") }}</a
                    >
                    <v-divider></v-divider>
                    <div class="py-2">
                      <div
                        class="d-flex align-items-center justify-content-between mb-2"
                      >
                        <span class="font-weight-bold mr-2"
                          >{{
                            $t("PAGES.RESERVATIONS.OVERVIEW.DATETIME")
                          }}:</span
                        >
                        <a class="text-muted text-hover-primary">{{
                          this.convertDateTime(this.event_date_time.date)
                        }}</a>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE") }}:</span>
                        <a class="text-muted text-hover-primary">{{
                            dose.title
                          }}</a>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">{{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE_DESCRIPTION") }}:</span>
                        <a class="text-muted text-hover-primary">{{
                            dose.description
                          }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Contact-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogConfirmation ref="confirm"></DialogConfirmation>
      <v-dialog
        :persistent="true"
        v-model="dialog_edit"
        :max-width="single_item_loading ? '300px' : '100%'"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <EditReservation
          v-bind:single-item-loading="single_item_loading"
          :single-item="reservationInUse"
          v-on:close-dialog="closeManageItem"
          @dialogLoading="updateDialogLoading"
        ></EditReservation>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>
<script>

export default {
  name: "step3ReservationWizard",
  data() {
    return {
      citizenInited: {
        city_of_birth: {},
        city_of_residence: {},
      },
      eventInited: {
        city: {},
        doses: {}
      },
      doseInited: {}
    }
  },
  props: {
    citizen: {},
    newcitizen: {},
    event: {},
    event_date_time: {},
    dose: {}
  },
  computed: {
    citizenResume() {
      return this.citizen.id != undefined ? this.citizen : this.citizenInited;
    },
    eventResume() {
      return this.event.id != undefined ? this.event : this.eventInited
    },
    doseResume() {
      return this.dose != undefined ? this.dose : this.doseInited
    },
  },
  methods: {
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      })
    }
  },
  mounted() {
  },
}
</script>
