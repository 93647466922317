<template>
  <div>
    <v-container>
      <v-toolbar-title
      >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.SEARCH_SAVED_USER") }}
      </v-toolbar-title>
      <v-row>
        <v-col cols="12" md="6">
          <div class="form-group">
            <v-autocomplete
                v-model="citizenSelected"
                :items="citizens_found"
                :loading="isLoadingCitizenSearch"
                :search-input.sync="citizens_search"
                filled
                :disabled="disabled"
                color="blue-grey lighten-2"
                :label="$t('PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.HELPTEXT')"
                :placeholder="$t('PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.SEARCH')"
                :filter="customCitizenFilter"
                return-object
                @change="citizenChange"
                clearable
            >
              <template slot="selection" slot-scope="{ item, tile }">
                {{ item.name }} {{ item.surname }} {{ item.fiscal_code }}
                {{ tile }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }} {{ data.item.surname }}
                {{ data.item.fiscal_code }}
              </template>
            </v-autocomplete>
            <div class="card card-custom card-stretch" v-show="citizen_block">
              <div class="card-body pt-4">
                <div class="d-flex justify-content-end"></div>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                        class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_USER") }}</a
                    >
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="py-2">
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.NAME") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.name
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.SURNAME") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        convertDate(citizenSelected.surname)
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.fiscal_code
                      }}</a>
                  </div>
                </div>
                <v-divider></v-divider>
                <!--begin::Contact-->
                <div class="py-2">
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.DATE_OF_BIRTH")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        convertDate(citizenSelected.date_of_birth)
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.CITY_OF_BIRTH")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.city_of_birth.denomination
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_ADDRESS")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.residence_address
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_CITY")
                      }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.city_of_residence.denomination
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.EMAIL") }}:</span
                    >
                    <a class="text-muted text-hover-primary">{{
                        citizenSelected.email
                      }}</a>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.PHONE") }}:</span
                    >
                    <span class="text-muted">{{ citizenSelected.phone }}</span>
                  </div>
                  <div
                      class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.MOBILE_PHONE")
                      }}:</span
                    >
                    <span class="text-muted">{{
                        citizenSelected.mobile_phone
                      }}</span>
                  </div>
                </div>
                <!--end::Contact-->
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider v-show="!citizen_block"></v-divider>
    <div v-show="!citizen_block">
      <v-container>
        <v-form ref="new_citizen_form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.name"
                  :disabled="disabled"
                  ret
                  filled
                  dense
                  :rules="citizenRequiredRule"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.NAME')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.surname"
                  ret
                  filled
                  dense
                  :disabled="disabled"
                  :rules="citizenRequiredRule"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.SURNAME')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.fiscal_code"
                  filled
                  dense
                  :disabled="disabled"
                  :rules="fiscalCodeRule"
                  v-on:blur="checkFiscalCode"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.FISCAL_CODE')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-toolbar-title
          >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.BIRTH_DATA") }}
          </v-toolbar-title>
          <v-row>
            <v-col cols="12" md="3">
              <DateBox
                  :disabled="disabled"
                  :final-date.sync="citizen_new.date_of_birth"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.DATE_OF_BIRTH')"
              ></DateBox>
            </v-col>
          </v-row>
          <LocationBoxes
              :countries-list="countriesList"
              :selectedCountry.sync="selectedCountryBirth"
              :selectedRegion.sync="selectedRegionBirth"
              :selectedState.sync="selectedStateBirth"
              :selectedCity.sync="selectedCityBirth"
              :disabled="disabled"
              :valid="true"
          ></LocationBoxes>
          <v-divider></v-divider>
          <v-toolbar-title
          >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.RESIDENCE_DATA") }}
          </v-toolbar-title>
          <LocationBoxesResidence
              :resident.sync="resident"
              :copyable="false"
              :copy-residence.sync="copy_residence"
              :countries-list="countriesList"
              :selectedCountry.sync="selectedCountryResidence"
              :selectedRegion.sync="selectedRegionResidence"
              :selectedState.sync="selectedStateResidence"
              :selectedCity.sync="selectedCityResidence"
              :valid="true"
              :disabled="disabled"
          ></LocationBoxesResidence>
          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                  v-model="citizen_new.residence_address"
                  ret
                  filled
                  dense
                  :disabled="disabled"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.ADDRESS')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                  v-model="citizen_new.residence_postal_code"
                  filled
                  dense
                  :disabled="disabled"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.ZIP')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.phone"
                  filled
                  dense
                  :disabled="disabled"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.PHONE')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.mobile_phone"
                  filled
                  dense
                  :disabled="disabled"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.MOBILE_PHONE')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  v-model="citizen_new.email"
                  filled
                  dense
                  :disabled="disabled"
                  color="blue-grey lighten-2"
                  :label="$t('PAGES.CITIZENS.FORM.MANAGE.EMAIL')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" offset="10">
              <button
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 mr-1"
                  @click="validate"
              >
                {{ button_text }}
              </button>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>

<script>
import {mapGetters} from "vuex";
import {
  CREATE_CITIZEN_DATA,
  FETCH_CITIZENS_CHECK,
  FETCH_CITIZENS_LIST
} from "@/core/services/store/citizens.module";
import DateBox from "@/view/commons/forms/DateBox";
import LocationBoxes from "@/view/commons/forms/LocationBoxes";
import LocationBoxesResidence from "@/view/commons/forms/LocationBoxesResidence";
import {FETCH_COUNTRIES_LIST} from "@/core/services/store/countries.module";
import rules from "@/core/helper/rules.helper";

export default {
  data() {
    return {
      button_text: this.$t("COMMON.BUTTON.SAVE_USER"),
      disabled: false,
      valid: false,
      singleItemLoading: false,
      isLoadingCitizenSearch: false,
      citizen_new: {
        // name: "Albina",
        // surname: "Cheliti",
        // fiscal_code: "CHLLBN63D51F524V",
        // date_of_birth: "11/4/1963",
        // city_of_birth: 7912,
        // city_of_residence: 7912,
        // residence_address: "Via Ribichini, 2",
        // residence_postal_code: "09012",
        // phone: "0039071369406",
        // mobile_phone: "00393731486042 ",
        // email: "chllbn63d51f524v@mailstop.it",
        // password: Math.random().toString(18).slice(2)
      },
      citizens_search: {},
      selectedCountryBirth: 380,
      selectedRegionBirth: 20,
      selectedStateBirth: Number,
      selectedCityBirth: Number,
      isLoadingCountries: 380,
      selectedCountryResidence: 380,
      selectedRegionResidence: 20,
      selectedStateResidence: Number,
      selectedCityResidence: Number,
      citizenSelected: {
        hidden: true,
        city_of_birth: {},
        city_of_residence: {}
      },
      citizenRequiredRule: [
        (v) => {
          return rules.requiredRules(v);
        }
      ],
      fiscalCodeRule: [
        (v) => {
          if (!v) return this.$t("COMMON.ERRORS.REQUIRED");
          return rules.fiscalCodeRule(this.fiscalCodeUsed);
        }
      ],
      citizens_filters: {
        query: [],
        filters: {
          _cmb_or: "_name,_surname,_fiscal_code",
          _name: {
            name: {
              _like: ""
            }
          },
          _surname: {
            surname: {
              _like: ""
            }
          },
          _fiscal_code: {
            fiscalCode: {
              _like: ""
            }
          }
        },
        sort: [
          {
            direction: "ASC",
            field: "surname"
          },
          {
            direction: "ASC",
            field: "name"
          }
        ],
        page: 1,
        limit: 50
      },
      filtersFiscalCode: {
        query: [],
        filters: {
          fiscalCode: {
            _eq: {}
          }
        },
        sort: [],
        page: 1,
        limit: 1
      },
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      }
    };
  },
  props: {
    citizen: {},
    newcitizen: {}
  },
  name: "step1ReservationWizard",
  mounted() {
    this.$store.dispatch(FETCH_COUNTRIES_LIST, this.empty_filters);
  },
  computed: {
    ...mapGetters([
      "citizensList",
      "citizenInCheck",
      "countriesList",
      "getSavingSuccess",
      "getCitizenSaved"
    ]),
    citizens_found() {
      return Object.assign([], this.citizensList.data);
    },
    citizen_block() {
      return this.citizenSelected != undefined &&
      this.citizenSelected.id != undefined
          ? true
          : false;
    },
    fiscalCodeUsed() {
      if (
          this.citizenInCheck != undefined &&
          this.citizenInCheck[0] != undefined
      ) {
        return this.citizenInCheck[0].fiscal_code.toLowerCase() ==
        this.citizen_new.fiscal_code.toLowerCase()
            ? true
            : false;
      }
      return false;
    }
  },
  methods: {
    validate() {
      if (this.disabled) {
        this.button_text = this.$t("COMMON.BUTTON.SAVED_USER");
        return;
      } else {
        this.button_text = this.$t("COMMON.BUTTON.SAVE_USER");
      }
      if (this.$refs.new_citizen_form.validate()) {
        this.elaborateForeign();
        this.citizen_new.city_of_domicile = this.citizen_new.city_of_residence;
        this.citizen_new.domicile_address = this.citizen_new.residence_address;
        this.citizen_new.domicile_postal_code =
            this.citizen_new.residence_postal_code;
        this.save();
      }
    },
    save() {
      this.$store.dispatch(CREATE_CITIZEN_DATA, this.citizen_new);
    },
    elaborateForeign() {
      if (this.selectedCountryBirth != 380) {
        this.citizen_new.bornAbroad = this.selectedCountryBirth;
      }
      if (this.selectedCountryResidence != 380) {
        this.citizen_new.residenceAbroad = this.selectedCountryResidence;
      }
      if (this.selectedCountryDomicile != 380) {
        this.citizen_new.domicileAbroad = this.selectedCountryDomicile;
      }
    },
    customCitizenFilter: function (item, queryText) {
      var name = item.name.toLowerCase();
      var surname = item.surname.toLowerCase();
      var fiscal_code = item.fiscal_code.toLowerCase();
      var searchText = queryText.toLowerCase();

      return (
          name.indexOf(searchText) > -1 ||
          surname.indexOf(searchText) > -1 ||
          fiscal_code.indexOf(searchText) > -1
      );
    },
    checkFiscalCode() {
      this.filtersFiscalCode.filters.fiscalCode._eq =
          this.citizen_new.fiscal_code;
      this.$store.dispatch(FETCH_CITIZENS_CHECK, this.filtersFiscalCode);
    },
    citizenChange: function (e) {
      if (e == null) {
        this.citizenSelected = {};
        this.citizenSelected.city_of_birth = {};
        this.citizenSelected.city_of_residence = {};
        this.citizenSelected.hidden = {};
      }
    },
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });
    }
  },
  watch: {
    citizenSelected() {
      this.$emit("update:citizen", this.citizenSelected);
      this.$emit("update:newcitizen", false);
    },
    getSavingSuccess() {
      if(this.getSavingSuccess == true){
        if (this.citizenSelected.id != undefined) {
          this.$emit("update:newcitizen", true);
        } else {
          this.$emit("update:newcitizen", false);
        }
        this.$emit("update:citizen", this.getCitizenSaved.data);
      }
      this.citizenSelected.id = this.getCitizenSaved.id;
      this.button_text = this.$t("COMMON.BUTTON.SAVED_USER");
      this.disabled = true;
    },

    citizens_found() {
      this.isLoadingCitizenSearch = false;
    },
    citizens_search(val) {
      // Items have already been loaded
      if (this.citizensList.length > 0) return;

      // Items have already been requested
      if (this.isLoadingCitizenSearch) return;

      this.isLoadingCitizenSearch = true;
      this.citizens_filters.filters._name.name._like = "%" + val + "%";
      this.citizens_filters.filters._surname.surname._like = "%" + val + "%";
      this.citizens_filters.filters._fiscal_code.fiscalCode._like =
          "%" + val + "%";
      this.$store.dispatch(FETCH_CITIZENS_LIST, this.citizens_filters);
    }
  },
  components: {DateBox, LocationBoxes, LocationBoxesResidence}
};
</script>
