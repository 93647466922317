<template>
  <div>
    <v-card tile>
      <v-container fill-height v-if="singleItemLoading">
        <v-layout row justify-center align-center>
          <v-progress-circular
              indeterminate
              :size="100"
              :width="7"
              color="purple"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-card>
    <div class="card card-custom card-transparent">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav">
            <div class="wizard-steps">
              <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
              >
                <div class="wizard-wrapper">
                  <div class="wizard-number">1</div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      {{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.TITLE") }}
                    </div>
                    <div class="wizard-desc">
                      {{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.SUB_TITLE") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-number">2</div>
                  <div class="wizard-label">
                    <div class="wizard-title">
                      {{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.TITLE") }}
                    </div>
                    <div class="wizard-desc">
                      {{ $t("PAGES.RESERVATIONS.NEW.STEPS.EVENT.SUB_TITLE") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-wrapper">
                  <div class="wizard-number">3</div>
                  <div class="wizard-label">
                    <div class="wizard-title">Completed</div>
                    <div class="wizard-desc">Review and Submit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body-->
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
              >
                <div class="col-md-12 col-xxl-7">
                  <!--begin: Wizard Form-->
                  <v-form
                      ref="new_reservation_form"
                      class="form mt-0 mt-lg-10"
                      id="kt_form"
                      v-model="valid"
                      lazy-validation
                  >
                    <!--begin: Wizard Step 1-->
                    <!--begin: Wizard Step 1-->
                    <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                    >
                      <Step1
                          :citizen.sync="citizen"
                          :newcitizen.sync="newcitizen"
                      ></Step1>
                    </div>
                    <!--begin: Wizard Step 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <Step2
                          :event.sync="event"
                          :event_date_time.sync="event_date_time"
                          :dose.sync="dose"
                          :citizen="citizen"
                          :duplicate.sync="duplicate"
                      ></Step2>
                    </div>
                    <!--end: Wizard Step 2-->

                    <!--begin: Wizard Step 3-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <Step3
                          :citizen="citizen"
                          :newcitizen="newcitizen"
                          :event="event"
                          :dose="dose"
                          :event_date_time="event_date_time"
                      ></Step3>
                    </div>
                    <!--end: Wizard Step 3-->

                    <!--begin: Wizard Actions -->
                    <div
                        class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2">
                        <button
                            class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-prev"
                        >
                          {{ $t("COMMON.BUTTON.PREV_STEP") }}
                        </button>
                      </div>
                      <div>
                        <button
                            v-on:click="submit"
                            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                            data-wizard-type="action-submit"
                        >
                          {{ $t("COMMON.BUTTON.CONFIRM_RESERVATION") }}
                        </button>
                        <button
                            class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                            :class="!showNextButton  ? 'orange' : ''"
                            @click="showAlertNextButton"
                            data-wizard-type="action-next"
                        >
                          {{ $t("COMMON.BUTTON.NEXT_STEP") }}
                        </button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </v-form>
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Bpdy-->
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
@import "~vuetify/dist/vuetify.css";
</style>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";
import Step1 from "@/view/pages/reservations/wizard/Step1";
import Step2 from "@/view/pages/reservations/wizard/Step2";
import Step3 from "@/view/pages/reservations/wizard/Step3";
import {CREATE_RESERVATIONS_DATA} from "@/core/services/store/reservations.module";

export default {
  data() {
    return {
      duplicate: false,
      wizardTmp: {},
      citizen: {},
      event: {},
      dose: {},
      event_date_time: {},
      newcitizen: false,
      valid: true,
      singleItemLoading: false,
      isLoadingCities: false,
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      creationData: {
        'dose': Number,
        reservation_date_time: String,
        citizen: Number,
        event: Number
      }
    };
  },
  name: "newReservation",
  computed: {
    ...mapGetters(["countriesList", "eventsList", "getReservationSavingSuccess"]),
    events_found() {
      return Object.assign([], this.eventsList.data);
    },
    showNextButton() {
      return (!this.showNextButtonStep1 || (!this.showNextButtonStep2 && this.duplicate)) ? false : true;
    },
    showNextButtonStep1() {
      return this.citizen.id != undefined ? true : false;
    },
    showNextButtonStep2() {
      return (this.event.id == undefined || this.event_date_time.date == undefined) ? false : true;
    },
    showSaveUserButton() {
      if (this.citizen.id == undefined && this.wizardTmp.currentStep == 1) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.RESERVATIONS.AREA")},
      {title: this.$t("PAGES.RESERVATIONS.NEW.TITLE")}
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });
    this.wizardTmp = wizard;
    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    showSwal() {
      Swal.fire({
        title: "",
        text: this.$t("COMMON.WIZARD.ALERT.COMPLETE_ALL_FIELDS"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    showAlertNextButton(e) {
      switch (this.wizardTmp.getStep()) {
        case 1:
          if (!this.showNextButtonStep1) {
            e.preventDefault();
            this.showSwal()
          } else {
            this.wizardTmp.goTo(1)
          }
          break;
        case 2:
          if (!this.showNextButtonStep2) {
            e.preventDefault();
            this.showSwal()
            this.wizardTmp.stop()
          } else {
            this.wizardTmp.goTo(2)
          }
          break;
        case 3:

          break;
      }

    },
    validate() {
      if (this.$refs.new_reservation_form.validate()) {
        this.elaborateForeign();
        this.save();
      }
    },
    submit: function (e) {
      e.preventDefault();
      this.creationData.citizen = this.citizen.id;
      this.creationData.event = this.event.id;
      this.creationData.status = 1;
      this.creationData.dose = this.dose.id;
      this.creationData.reservation_date_time = this.event_date_time.date;
      this.$store.dispatch(CREATE_RESERVATIONS_DATA, this.creationData);
    },
    convertDate(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      });
    }
  },
  watch: {
    getReservationSavingSuccess() {
      if (this.getReservationSavingSuccess) {
        Swal.fire({
          title: "",
          text: this.$t("PAGES.RESERVATIONS.NEW.TITLE_SUCCESS"),
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }).then(() => {
          this.singleItem = {};
          this.$router.push({name: "reservations_list"})
        }
      );
      } else {
        Swal.fire({
          title: "",
          text: this.$t("PAGES.RESERVATIONS.NEW.TITLE_ERROR"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    }
  },
  components: {
    Step1, Step2, Step3
  }
}
;
</script>
